
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { useEffect } from 'react';
// import dynamic from "next/dynamic";
import Head from 'next/head'
import { GTMPageView } from './../helpers/gtm';
// const Overlay = dynamic(() => import("../components/Overlay"));
import "../styles/global.scss";


function MyApp({ Component, pageProps }) {
  
  useEffect(() => {
    GTMPageView();
  }, []);


  return (
    <>
    {/* <Overlay
        isOverlay={false}
        overlayValue={'NAVIGATION'}
        // coloseOverlay={coloseOverlay}
      /> */}
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#e95164" />
        <meta name="msapplication-navbutton-color" content="#e95164" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#e95164" />
        <meta name="p:domain_verify" content="2c7e78eed3043103ec9a103310f71648" />
        <meta name="fragment" content="!" />
      </Head>
      <div id="psloader">
        <div className="loader"></div>
      </div>
      <div style={{position: "relative"}}>
      <Component {...pageProps} />
      </div>
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  