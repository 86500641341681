// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if(process.env.APPENV === 'prod'){
  Sentry.init({
    ignoreErrors:[
      "Non-Error exception captured",
      "Non-Error promise rejection captured", 
      "Illegal invocation", // these three has investigated and decieded to ignore
      "failed to decode param" // node pkg issue
     ],
    dsn: SENTRY_DSN || 'https://1e0251d95a14436cb3776a6ff5318bf9@o212205.ingest.sentry.io/1338609',
    release: "nex-ui-ssr-home@" + process.env.VERSION,
    environment: process.env.SENTRY_ENVIRONMENT,
    enabled: process.env.SENTRY_ENABLE === 'yes',
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}

